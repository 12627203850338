import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../utils/isAuthenticated';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated()
          ? <Component {...props} />
          : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
